/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow: 
    0px 0px 0px 1px #E0B4B4 inset, 
    0px 2px 4px 0px rgba(34, 36, 38, 0.12), 
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 16px;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 16px;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

.ui.search > .results .result {
  overflow-wrap: break-word;
}

.ui.search > .results .result:hover {
  background-color: lightgrey;
}

.ui.table tr.active,
.ui.table tr:hover {
  background: lightgrey !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.multiple.dropdown {
  width: 150px;
}

.ui.multiple.dropdown>.label{
  font-size: 14px;
}

.ui.selection.dropdown .menu>.item {
  min-height: 37px;
}

.ui.dropdown .menu > .item:hover {
  background-color: lightgrey;
}

 /* 
This can be uncommented if some values are not appearing
.ui.selection.dropdown .menu {
  overflow-x: scroll; 
}
*/ 

.ui.selection.active.dropdown .menu {
  width: auto;
}

.ui.form .disabled.fields .field,
.ui.form .disabled.field,
.ui.form .field :disabled {
  opacity: unset;
}

.ui.form .field.disabled>label,
.ui.form .fields.disabled>label {
  opacity: unset;
}
.ui.label{
  width: 150px;
}

.ui.card {
  width: 290px;
  height: 157px;
  margin: -0.875em -0.5em;
  flex-wrap: wrap;
}
.footer{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.ui.header>.image:not(.icon), .ui.header>img {
  display: inline-block;
  margin-top: 0;
  width: 20px;
  height: auto;
  vertical-align: middle;
}

.styles-article {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.styles-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.styles-header {
  font-size: xx-large;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
} 

.ui.vertical.segment {
  height: 50%;
} 

.ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui) {
  text-align: center;
} 

.ui .errMsg {
  font-weight: bold;
}

.ui .errInstancePath {
  font-size: 12px;
}
.ui.container.sidebar-resizer-container {
  width: 30px;
  height: 100%;
  position: absolute;
  display: flex;
}

.sidebar-collapse {
  z-index: 101;
  margin-top: 5px;
  margin-left: 3px;
  width: 20px;
  height: 24px;
  padding: var(--ds-space-0, 0px);
  background-color: var(--ds-surface-overlay, #fff);
  border-radius: var(--ds-border-radius-circle, 20%);
  color: var(--ds-text-subtle, #6b778c);
  cursor: pointer;
  opacity: 1;
  transform-origin: 7px;
  border: 0;
  outline: 0;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  box-shadow: 0 0 0 2px #091e4214, 0 2px 4px 1px #091e4214;
}

.sidebar-expand {
  z-index: 101;
  margin-top: 5px;
  margin-left: 3px;
  width: 20px;
  height: 24px;
  padding: var(--ds-space-0, 0px);
  background-color: var(--ds-surface-overlay, #fff);
  border-radius: var(--ds-border-radius-circle, 20%);
  color: var(--ds-text-subtle, #6b778c);
  cursor: pointer;
  opacity: 1;
  transform-origin: 7px;
  border: 0;
  outline: 0;
  position: absolute;
  box-shadow: 0 0 0 2px #091e4214, 0 2px 4px 1px #091e4214;
}

.sidebar-collapse:hover {
  background-color: var(--ds-surface-overlay, #eeecec);
}

.sidebar-expand:hover {
  background-color: var(--ds-surface-overlay, #eeecec);
}

.collapsible-arrow {
  margin: 6px 0px 0px 2px;
  width: 15px;
  height: 15px;
}

.sidebar-resizer {
  z-index: 100;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.sidebar-resizer:hover {
  width: 3px;
  background: #c1c3c5b4;
}

.ui.pushable .sidebar-resizer-container .sidebar-collapsed {
  width: 5px;
  background-color: rgb(27, 28, 29);
  z-index: 102;
}
.error-message-box {
  background-color: #ffe6e6;
  color: #a00;
  padding: 1rem;
  border: 1px solid #ff0000;
  margin: 1rem 0;
  border-radius: 4px;
}

.error-message-box:hover {
  background-color: #fff0f0;
  cursor: pointer;
}